import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { ColorSchema } from '#/theme/palette';

type TrafficLightProps = {
  isActive: number;
};

type TrafficLight = {
  color: 'success' | 'warning' | 'error';
  isActive?: boolean;
};

const TRAFFIC_LIGHTS: TrafficLight[] = [
  { color: 'error' },
  { color: 'warning' },
  { color: 'success' },
];

export default function TrafficLights({ isActive }: TrafficLightProps) {
  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      spacing={6}
      justifyContent="center"
      sx={{ marginY: 6 }}
    >
      {TRAFFIC_LIGHTS.map((light, index) => (
        <StyledTrafficLight
          key={light.color}
          color={light.color}
          isActive={index === isActive}
          role="status"
          aria-live={index === isActive ? 'polite' : 'off'}
        />
      ))}
    </Stack>
  );
}

const StyledTrafficLight = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean; color: 'success' | 'warning' | 'error' }>(
  ({ isActive, color, theme }) => ({
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    backgroundColor: isActive
      ? theme.palette[color as ColorSchema].main
      : theme.palette.grey[400],
    transition: 'background-color 0.3s ease',
  })
);
