import { Box, Card, Typography } from '@mui/material';
import { ReactNode } from 'react';

type RecommendationCardProps = {
  title: string;
  children?: ReactNode;
};

export default function RecommendationCard({
  title,
  children,
}: RecommendationCardProps) {
  return (
    <Card sx={{ p: 3, mt: 4 }}>
      <Box
        sx={{
          width: '100%',
          '& > div': {
            marginTop: 2,
          },
        }}
      >
        <Typography variant="subtitle1">{title}</Typography>
        {children}
      </Box>
    </Card>
  );
}
