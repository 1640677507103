import useLocales from '#/hooks/useLocales';
import RecommendationCard from '#/components/pages/PensionProvision/onboarding/steps/profiling/recommendations/card';
import { PROVISION_TOPICS } from '#/components/pages/PensionProvision/onboarding/data';

export default function GreenLightRecommendations() {
  const { translate } = useLocales();

  return (
    <RecommendationCard
      title={String(
        translate(
          'pensionProvision.onboarding.stepThree.greenLightRecommendation.title'
        )
      )}
    />
  );
}
